










































































































import { Component, Vue } from "vue-property-decorator";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { DATE_FORMAT_DD_MMM_YYYY_HH_mm } from "@/models/constants/date.constant";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { mapMutations } from "vuex";

const Props = Vue.extend({
  props: [
    "handleInputTableUnitCode",
    "dataTableGenerateInvoice",
    "selectedRowKeysTableGenerateInvoice",
    "disabled",
    "searchData",
    "getCheckboxPropsTableGenerateInvoice",
    "handleInputTableGenerateInvoice",
    "handleCustomData",
    "dataBankName",
    "loading",
    "handleSelectTableGenerateInvoice",
    "handleDateMonthGenerateInvoice",
  ],
});

/**
 * @deprecated
 * new component {@linkcode FormPayment.vue}
 */
@Component({
  inject: {
    formHeader: {
      from: "formHeader",
    },
  },
  methods: {
    ...mapMutations({
      setBankId: "leasingStore/setBankId",
    }),
  },
})
export default class TableGenerateInvoice extends Props {
  formHeader!: WrappedFormUtils;

  setBankId!: (val: string) => void;

  columnsTable = [
    {
      title: this.$t("lbl_billing_period"),
      dataIndex: "installment",
      key: "installment",
      width: 200,
      fixed: "left",
      scopedSlots: { customRender: "isNull" },
      responsiveColDateMonth: [
        {
          name: "paymentDate",
          placeholder: this.$t("lbl_payment_date_placeholder"),
          style: "width: 100%;",
          disabled: "",
        },
        {
          name: "realization",
          style: "width: 100%;",
          showTime: true,
          disabled: false,
          format: DATE_FORMAT_DD_MMM_YYYY_HH_mm,
        },
      ],
      responsiveColSelect: [
        {
          name: "paymentType",
          placeholder: this.$t("lbl_payment_type_placeholder"),
          style: "width: 100%;",
          value: "id",
          option: [] as any[],
        },
      ],
      responsiveColInput: [
        {
          name: "paymentNumber",
          placeholder: this.$t("lbl_payment_number"),
          style: "width: 100%;",
        },
        {
          name: "principalCost",
          placeholder: this.$t("lbl_principal_cost"),
          style: "width: 100%;",
        },
        {
          name: "interestCost",
          placeholder: this.$t("lbl_interest_cost"),
          style: "width: 100%;",
        },
      ],
    },
    {
      title: this.$t("lbl_payment_number"),
      dataIndex: "paymentNumber",
      key: "paymentNumber",
      width: 300,
      fixed: "left",
      scopedSlots: { customRender: "paymentNumber" },
    },
    {
      title: this.$t("lbl_payment_type"),
      dataIndex: "paymentType",
      key: "paymentType",
      width: 300,
      scopedSlots: { customRender: "paymentType" },
    },
    {
      title: this.$t("lbl_payment_date"),
      dataIndex: "paymentDate",
      key: "paymentDate",
      width: 300,
      scopedSlots: { customRender: "paymentDate" },
    },
    {
      title: this.$t("lbl_principal_cost"),
      dataIndex: "principalCost",
      key: "principalCost",
      width: 300,
      scopedSlots: { customRender: "principalCost" },
    },
    {
      title: this.$t("lbl_interest_cost"),
      dataIndex: "interestCost",
      key: "interestCost",
      width: 300,
      scopedSlots: { customRender: "interestCost" },
    },
    {
      title: this.$t("lbl_installment"),
      dataIndex: "paymentCost",
      key: "paymentCost",
      width: 150,
      scopedSlots: { customRender: "isNull" },
      disabled: true,
    },
    {
      title: this.$t("lbl_balance"),
      dataIndex: "balance",
      key: "balance",
      width: 200,
      scopedSlots: { customRender: "isNull" },
      disabled: true,
    },
    {
      title: this.$t("lbl_realization"),
      dataIndex: "realization",
      key: "realization",
      width: 300,
      scopedSlots: { customRender: "realization" },
    },
    {
      title: this.$t("lbl_generate_payment_leasing"),
      dataIndex: "generate",
      key: "generate",
      width: 250,
      scopedSlots: { customRender: "buttonCustom" },
    },
    {
      title: this.$t("lbl_cancel_payment"),
      dataIndex: "cancelPayment",
      width: 250,
      scopedSlots: { customRender: "buttonCustom" },
    },
    {
      title: this.$t("lbl_view_payment_leasing"),
      dataIndex: "view",
      key: "view",
      width: 205,
      scopedSlots: { customRender: "buttonCustom" },
    },
  ] as ColumnTableCustom[];
  formRules = {
    bankName: {
      label: "lbl_bank_name",
      name: "bankName",
      placeholder: "lbl_bank_name_placeholder",
      decorator: ["bankName"],
    },
    totalInsurance: {
      label: "lbl_total_insurance",
      name: "lbl_total_insurance",
      placeholder: "lbl_total_insurance",
      decorator: ["totalInsurance"],
    },
    totalPrincipalCost: {
      label: "lbl_total_principal_cost",
      name: "lbl_total_principal_cost",
      placeholder: "lbl_total_principal_cost",
      decorator: ["totalPrincipalCost"],
    },
    totalInterestCost: {
      label: "lbl_total_interest_cost",
      name: "lbl_total_interest_cost",
      placeholder: "lbl_total_interest_cost",
      decorator: ["totalInterestCost"],
    },
    totalInstallment: {
      label: "lbl_total_installment",
      name: "lbl_total_installment",
      placeholder: "lbl_total_installment",
      decorator: ["totalInstallment"],
    },
  };

  onChangeBank(e: string | null): void {
    this.formHeader.setFieldsValue({
      bankName: e,
    });

    const option = this.dataBankName.find(item => item.name === e);

    this.setBankId(option?.id || "");
  }
}
